exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-book-online-index-js": () => import("./../../../src/pages/book-online/index.js" /* webpackChunkName: "component---src-pages-book-online-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gifted-guidance-js": () => import("./../../../src/pages/gifted-guidance.js" /* webpackChunkName: "component---src-pages-gifted-guidance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-the-waitlist-js": () => import("./../../../src/pages/join-the-waitlist.js" /* webpackChunkName: "component---src-pages-join-the-waitlist-js" */),
  "component---src-pages-reiki-landing-js": () => import("./../../../src/pages/reiki-landing.js" /* webpackChunkName: "component---src-pages-reiki-landing-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-testimonial-js": () => import("./../../../src/pages/testimonial.js" /* webpackChunkName: "component---src-pages-testimonial-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-book-online-js": () => import("./../../../src/templates/book-online.js" /* webpackChunkName: "component---src-templates-book-online-js" */),
  "component---src-templates-mediumship-js": () => import("./../../../src/templates/mediumship.js" /* webpackChunkName: "component---src-templates-mediumship-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-reiki-js": () => import("./../../../src/templates/reiki.js" /* webpackChunkName: "component---src-templates-reiki-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

